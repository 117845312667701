<template>
<div>
    <div class="fixed-top" v-if="$device.isDesktopOrTablet"><!-- Start: pc -->
        <header class="position-relative bg-home"><!-- Start: menu pc -->
            <div class="container">
                <div class="row g-0 w-100"><!-- Start: logo -->
                    <div class="col-md-2 col-lg-2 justify-content-center align-items-center">
                        <div class="d-flex d-xxl-flex flex-row justify-content-between align-items-center pointer h-100 w-100 pb-2"><nuxt-link-locale class="h-100 text-decoration-none" to="/" aria-label="trang chủ">
                                <div class="d-flex flex-row align-items-center h-100 pointer"><img class="img-fluid w-100 logo-img" alt="logo" :src="websiteConfig.logo"></div>
                            </nuxt-link-locale></div>
                    </div><!-- End: logo --><!-- Start: tìm kiếm (tk) -->
                    <div class="col-auto col-md-5 justify-content-center align-items-center justify-content-lg-center">
                        <div class="d-flex flex-column justify-content-xxl-center w-100 h-100 px-2 position-relative home-search pb-2">
                            <div class="d-flex header-hightlight-group-product-slide">
                                <div class="text-white pointer" v-for="(groupProductLv1,index) in (groupProductLv1s||[]).filter((itss,itx) => itx<3)" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="text-decoration-none text-white" aria-label="Danh mục nổi bật" :to="'/danh-muc/'+groupProductLv1.slug"><span class="me-2 text-dark">{{groupProductLv1.name}}</span></nuxt-link-locale></div>
                            </div><!-- Start: search-input -->
                            <div class="position-relative"><!-- Start: control -->
                                <div class="d-flex w-100 h-100 input-container" :class="{'open':keyword!==''}">
                                    <form class="d-flex justify-content-between align-items-center w-100 search-form" method="get" action="/search"><!-- Start: search --><input class="form-control search-input-default" type="text" placeholder="Tìm kiếm sản phẩm tại cửa hàng" name="keyword" v-model="keyword" @update:model-value="inputtext7b40660e80d4dc67d8050d8e0ae106fea888b0ddupdatemodelvalue"><!-- End: search --><button class="btn" type="button" aria-label="Tìm kiếm"><i class="icon-magnifier"></i></button></form>
                                </div><!-- End: control --><!-- Start: hiển thị thông tin tìm kiếm của input -->
                                <div class="suggestions-box top-less-box-shadow" v-if="keyword!==''"><!-- Start: list product -->
                                    <div class="mt-3 mb-2 px-1"><!-- Start: item -->
                                        <div v-for="(listProductSearch,index) in listProductSearchs" v-bind:key="listProductSearch._id"><!-- Start: product -->
                                            <div class="d-flex" @click="keyword=''"><nuxt-link-locale class="text-decoration-none text-dark w-100" aria-label="Sản phẩm" :to="'/san-pham/'+listProductSearch.slug">
                                                    <div class="d-flex p-1 hover-menu w-100">
                                                        <div class="search-img"><img class="img-fluid" :src="listProductSearch.image" :alt="listProductSearch.name"></div>
                                                        <div class="d-flex flex-column fs-14 p-1 search-desc">
                                                            <p class="pm-0 tx-price">{{formatNumberMoney(listProductSearch.price)}}</p>
                                                            <p class="pm-0">{{listProductSearch.name}}</p>
                                                        </div>
                                                    </div>
                                                </nuxt-link-locale></div><!-- End: product -->
                                        </div><!-- End: item -->
                                    </div><!-- End: list product --><!-- Start: list brands -->
                                    <div class="px-1">
                                        <h6 class="mb-0 px-1">Tìm theo thương hiệu</h6>
                                        <div class="pb-3 px-1">
                                            <div class="row g-0 d-flex g-2">
                                                <div class="col-4" v-for="(searchProductBrands,index) in searchProductBrandss" v-bind:key="searchProductBrands._id" @click="keyword=''"><nuxt-link-locale class="a-decoration-none" aria-label="thương hiệu tìm kiếm" :to="'/thuong-hieu/'+searchProductBrands.slug">
                                                        <div class="search-brand"><img class="img-fluid w-100" :src="searchProductBrands.banner" :alt="searchProductBrands.name"></div>
                                                    </nuxt-link-locale></div>
                                            </div>
                                        </div>
                                    </div><!-- End: list brands -->
                                </div><!-- End: hiển thị thông tin tìm kiếm của input -->
                            </div><!-- End: search-input -->
                        </div>
                    </div><!-- End: tìm kiếm (tk) -->
                    <div class="col-md-5 flex-row justify-content-end align-items-center">
                        <div class="d-flex justify-content-around align-items-center w-100 h-100"><!-- Start: ngôn ngữ -->
                            <div class="align-items-center" @click="showlanguage=!showlanguage"><!-- Start: Ngôn ngữ -->
                                <div class="d-flex flex-column justify-content-end align-items-center me-2">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇻🇳.png" alt="logo" @click="imageda5a33991d426f07d5ee57addc75a8f9c53cec9cclick"></div>
                                        <div class="pointer me-2"><img class="img-icon-header" src="/static/cosmeticscz/🇨🇿.png" alt="logo" @click="image9f919bf306562acd1aed951ba691078385243a71click"></div>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center txt-header-right pb-1"><span>Tiếng việt</span></div>
                                </div><!-- End: Ngôn ngữ -->
                            </div><!-- End: ngôn ngữ --><!-- Start: đang nhập -->
                            <div class="d-flex align-items-center account-info h-100"><!-- Start: đăng nhập đăng ký -->
                                <div class="d-flex ms-2"><!-- Start: tài khoản -->
                                    <div class="d-flex align-items-start pointer"><!-- Start: img -->
                                        <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_2.png" alt="tài khoản"></div><!-- End: img --><!-- Start: tài khoản -->
                                        <div class="d-flex flex-column justify-content-start align-items-start justify-content-xxl-center txt-header-right ms-2 text-dark h-100" v-if="userGetMe._id===undefined" @click="userGlobalState.isshowloginform=true"><span>{{$t('dangNhapDangKy')}}</span><span>Tài khoản</span></div><!-- End: tài khoản --><!-- Start: tài khoản đã đăng nhập -->
                                        <div class="d-flex flex-column justify-content-center align-items-start txt-header-right ms-2 text-dark h-100" v-if="userGetMe._id!==undefined"><span>Xin chào</span><span>{{userGetMe.fullName}}</span></div><!-- End: tài khoản đã đăng nhập -->
                                    </div><!-- End: tài khoản -->
                                </div><!-- End: đăng nhập đăng ký --><!-- Start: menu con -->
                                <div class="menu-account" v-if="userGetMe._id!==undefined">
                                    <div class="d-flex flex-column category"><!-- Start: tài khoản của bạn -->
                                        <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" aria-label="tải khoản của bạn" to="/tai-khoan"><i class="icon-calendar me-1"></i><span>{{$t('taiKhoanCuaBan')}}</span></nuxt-link-locale></div><!-- End: tài khoản của bạn --><!-- Start: quản lý đơn hang -->
                                        <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" aria-label="đơn hàng của bạn" to="/tai-khoan/don-hang"><i class="icon-notebook me-1"></i><span>{{$t('quanLyDonHang')}}</span></nuxt-link-locale></div><!-- End: quản lý đơn hang --><!-- Start: Sản phẩm yêu thích -->
                                        <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" aria-label="sản phẩm yêu thích" to="/tai-khoan/yeu-thich"><i class="icon-heart me-1"></i><span>{{$t('sanPhamYeuThich')}}</span></nuxt-link-locale></div><!-- End: Sản phẩm yêu thích --><!-- Start: Hỗ trợ -->
                                        <div class="fs-14 py-2 pointer px-2 hover-effect"><nuxt-link-locale class="a-decoration-none" aria-label="hõ trợ" to="/ho-tro"><i class="icon-support me-1"></i><span>Hỗ trợ khách hàng</span></nuxt-link-locale></div><!-- End: Hỗ trợ --><!-- Start: Thoát -->
                                        <div class="fs-14 py-2 pointer px-2 hover-effect" @click="div3a44be39293091f259df8fbef40971acfc3b93c6click"><i class="icon-login me-1"></i><span>{{$t('thoat')}}</span></div><!-- End: Thoát -->
                                    </div>
                                </div><!-- End: menu con -->
                            </div><!-- End: đang nhập --><!-- Start: hỗ trợ -->
                            <div class="align-items-center"><!-- Start: hỗ trợ -->
                                <div class="d-flex align-items-center ms-2"><nuxt-link-locale class="text-decoration-none" to="/ho-tro">
                                        <div class="d-flex pointer">
                                            <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_3.png" alt="hỗ trợ khách hàng"></div>
                                            <div class="d-flex flex-column justify-content-center txt-header-right ms-2 text-dark"><span>Hỗ trợ&nbsp;</span><span>khách hàng</span></div>
                                        </div>
                                    </nuxt-link-locale></div><!-- End: hỗ trợ -->
                            </div><!-- End: hỗ trợ --><!-- Start: giỏ hàng -->
                            <div class="align-content-center"><!-- Start: giỏ hàng -->
                                <div class="d-flex justify-content-around align-items-center pointer position-relative ms-2"><nuxt-link-locale to="/gio-hang">
                                        <div><img class="img-icon-header" src="/static/cosmeticscz/icon_header_1.png" alt="giỏ hàng"></div>
                                        <div class="d-flex justify-content-center align-items-center icon-cart"><span class="text-white">{{productCart?.length}}</span></div>
                                    </nuxt-link-locale></div><!-- End: giỏ hàng -->
                            </div><!-- End: giỏ hàng -->
                        </div>
                    </div>
                </div>
            </div><!-- End: menu pc --><!-- Start: list danh mục -->
            <div class="bg-home-menu">
                <div class="container position-relative">
                    <div class="d-flex pointer position-relative bg-home-menu"><!-- Start: nav-2 -->
                        <div class="d-flex"><!-- Start: danh mucj -->
                            <div class="pe-2 py-2 dm-div z-3"><i class="icon-menu pe-2"></i>
                                <p class="mb-0 pm-0 danhmuc-text">{{$t('danhMuc')}}</p><!-- Start: menu-lv1 -->
                                <div class="menu-lv1 w-100"><!-- Start: left -->
                                    <div class="left position-relative bg-white h-100"><!-- Start: item -->
                                        <div class="item" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id">
                                            <nuxt-link-locale class="a-decoration-none" aria-label="danh mục lớn" :to="'/danh-muc/'+groupProductLv1.slug">
                                                <span class="d-flex justify-content-between align-items-center title-lv1">
                                                    <!-- danh mục cấp 1 -->
                                                    <span v-if="userGlobalState.language === 'vi'">{{groupProductLv1.name}}</span> 
                                                    <span v-else>{{groupProductLv1.secondName}}</span> 
                                                    <i class="icon-arrow-down"></i>
                                                </span>
                                            </nuxt-link-locale><!-- Start: right -->
                                            <div class="right">
                                                <div class="w-100 h-100">
                                                    <div class="row g-0 d-flex h-100">
                                                        <div class="col-9 bg-white"><!-- Start: gallery List-menu -->
                                                            <div class="h-100 bg-white gallery"><!-- Start: gallery-item item-by-lv2 -->
                                                                <div class="gallery-item mb-1" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id">
                                                                    <div v-if="groupProductLv2.groupProduct?.includes(groupProductLv1._id)"><!-- Start: item-menu -->
                                                                        <div class="item-menu px-1 pb-1">
                                                                            <nuxt-link-locale class="a-decoration-none" aria-label="danh mục cấp 2" :to="'/danh-muc/'+groupProductLv2.slug">
                                                                                <!-- danh mục cấp 2 -->
                                                                                <span v-if="userGlobalState.language === 'vi'" class="title-lv-2 mb-1">{{groupProductLv2.name}}</span>
                                                                                <span v-else class="title-lv-2 mb-1">{{groupProductLv2.secondName}}</span>
                                                                            </nuxt-link-locale><!-- Start: menu-lv-3 -->
                                                                            <div class="menu-lv3 ps-2"><!-- Start: item-lv-3 -->
                                                                                <div v-for="(groupProductLv3,index) in groupProductLv3s" v-bind:key="groupProductLv3._id">
                                                                                    <div v-if="groupProductLv3.groupProduct?.includes(groupProductLv2._id)">
                                                                                        <nuxt-link-locale class="a-decoration-none" aria-label="danh mục cấp 3" :to="'/danh-muc/'+groupProductLv3.slug">
                                                                                            <!-- danh muc cap 3 -->
                                                                                            <span v-if="userGlobalState.language === 'vi'" class="item-lv3">{{groupProductLv3.name}}</span>
                                                                                            <span v-else class="item-lv3">{{groupProductLv3.secondName}}</span>
                                                                                        </nuxt-link-locale></div>
                                                                                </div><!-- End: item-lv-3 -->
                                                                            </div><!-- End: menu-lv-3 -->
                                                                        </div><!-- End: item-menu -->
                                                                    </div>
                                                                </div><!-- End: gallery-item item-by-lv2 -->
                                                            </div><!-- End: gallery List-menu -->
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="d-flex justify-content-end justify-content-xxl-start align-items-xxl-end h-100 menu-img-div"><!-- Start: image -->
                                                                <div class="menu-img"><img :src="groupProductLv1.icon" :alt="groupProductLv1.name"></div><!-- End: image -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- End: right -->
                                        </div><!-- End: item -->
                                    </div><!-- End: left -->
                                </div><!-- End: menu-lv1 -->
                            </div><!-- End: danh mucj -->
                        </div><!-- End: nav-2 -->
                        <div class="d-flex">
                            <div class="d-flex align-items-center py-1 px-2 menu-category" v-for="(listHomeMenu,index) in listHomeMenus" v-bind:key="listHomeMenu._id"><nuxt-link-locale class="a-decoration-none" aria-label="hot deal" :to="listHomeMenu.link">
                                <span v-if="userGlobalState.language==='vi'" >{{listHomeMenu.label}}</span>
                                <span v-else >{{listHomeMenu.secondLabel}}</span>
                            </nuxt-link-locale></div>
                        </div>
                    </div>
                </div>
            </div><!-- End: list danh mục -->
        </header><!-- End: pc -->
    </div>
    <div class="header-pc-clone" v-if="$device.isDesktopOrTablet"></div>
    <div class="fixed-top" v-if="$device.isMobile"><!-- Start: nav -->
        <header class="position-relative bg-home">
            <div class="container"><!-- Start: menu phone -->
                <div class="home-search position-relative">
                    <div class="d-flex flex-row justify-content-between align-items-center w-100 pt-1 mobile-header"><!-- Start: tog -->
                        <div class="flex-column justify-content-center justify-content-sm-center h-100 pointer logo">
                            <div><nuxt-link-locale class="a-decoration-none" to="/" aria-label="Trang chủ ChangChangCosmetic"><img class="img-fluid" alt="logo ChangChang" :src="websiteConfig.logo"></nuxt-link-locale></div>
                        </div><!-- End: tog --><!-- Start: search-mobile -->
                        <div class="d-flex flex-row align-items-center mobile-search-default w-50" @click="showcanvassearchmobile=true">
                            <form class="w-100"><i class="icon-magnifier me-2"></i><span>Tìm kiếm</span></form>
                        </div><!-- End: search-mobile --><!-- Start: giỏ hàng -->
                        <div class="d-flex position-relative mobile-header-cart-item h-100 pt-1"><nuxt-link-locale class="a-decoration-none" to="/gio-hang" aria-label="giỏ hàng"><i class="icon-basket p-1 h-100"></i></nuxt-link-locale><span class="cart-count-mobile">{{productCart?.length}}</span></div><!-- End: giỏ hàng -->
                    </div>
                </div><!-- End: menu phone -->
            </div>
        </header><!-- End: nav -->
    </div>
    <div class="mask-overlay" v-if="$device.isMobile"><!-- Start: nav -->
        <header class="position-relative bg-home">
            <div class="container"><!-- Start: menu phone -->
                <div class="home-search position-relative">
                    <div class="d-flex flex-row justify-content-between align-items-center w-100 pt-1 mobile-header"><!-- Start: tog -->
                        <div class="flex-column justify-content-center justify-content-sm-center h-100 pointer logo">
                            <div @click="showmenugoc=!showmenugoc"><img class="img-fluid" alt="logo ChangChang" :src="websiteConfig.logo"></div>
                        </div><!-- End: tog -->
                    </div>
                </div><!-- End: menu phone -->
            </div>
        </header><!-- End: nav -->
    </div>
    <div>
        <main>
            <slot/>
        </main>
    </div>
    <section class="bg-bdy">
        <footer><!-- Start: slogan-footer -->
            <div>
                <div class="container"><!-- Start: slogan-pc -->
                    <div class="row g-0 br-bot-footer w-100 bg-white" v-if="$device.isDesktopOrTablet">
                        <div class="col-4">
                            <div class="d-flex justify-content-between align-items-center w-100 py-3 h-100">
                                <div class="slogan-div" v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id"><nuxt-link-locale class="a-decoration-none" user-agent:="" allow:="" sitemap:="" https:="" beautyplus.asia="" sitemap.xml="" aria-label="hỗ trợ" :to="'/ho-tro/'+webContentsVeHasaki.slug">
                                        <div class="px-1" v-if="webContentsVeHasaki.groupWebContent==='Thông tin và slogan'">
                                            <div class="d-flex justify-content-center"><img class="img-fluid img-footer" alt="icon-slogan" :src="webContentsVeHasaki.thumbnail"></div>
                                        </div>
                                    </nuxt-link-locale></div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="d-flex justify-content-between align-items-center px-2 py-2 py-md-3 h-100 w-100">
                                <div>
                                    <div class="d-flex justify-content-center align-items-center mb-2"><img class="img-common" src="/static/cosmeticscz/icons_hotline_kn.png" alt="logo"><span class="ms-2">KHIẾU NẠI, GÓP Ý</span></div>
                                    <div class="justify-content-center align-items-center">
                                        <div class="justify-content-center align-items-center bg-home boder-info"><nuxt-link-locale class="a-decoration-none" aria-label="Góp ý - khiếu nại" :to="'mailto:'+websiteConfig.email">
                                                <p class="text-center pm-0 text-white">{{websiteConfig.email}}</p>
                                            </nuxt-link-locale></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center mb-2"><img class="img-common" src="/static/cosmeticscz/icons_hotline_tv.png" alt="logo"><span class="ms-2">TƯ VẤN</span></div>
                                    <div class="justify-content-center align-items-center">
                                        <div class="d-flex justify-content-center align-items-center bg-home boder-info"><nuxt-link-locale class="a-decoration-none" aria-label="liên hệ tư vấn" :to="'tel:'+websiteConfig.soDienThoai">
                                                <p class="pm-0 text-white">{{websiteConfig.soDienThoai}}</p>
                                            </nuxt-link-locale></div>
                                    </div>
                                </div>
                                <div class="p-3 border-1px-main w-50">
                                    <h4 class="text-uppercase title-space-bottom">Cập nhật thông tin khuyến mãi</h4>
                                    <div class="d-flex input-container"><input type="email" placeholder="Email của bạn" v-model="thongTinLienHe.email"><button class="btn btn-primary btn-seach h-100" type="button" aria-label="đăng ký nhận tin" @click="()=>{let stepBreak=0;;if( (thongTinLienHe.email==='')&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Vui lòng nhập địa chỉ email',variant:'danger'}})};if( thongTinLienHe.email==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){createThongtinlienhe()}}">Đăng ký</button></div>
                                </div>
                            </div>
                        </div>
                    </div><!-- End: slogan-pc -->
                    <div v-if="$device.isMobile"><!-- Start: list -->
                        <div><!-- Start: item -->
                            <div></div><!-- End: item -->
                        </div><!-- End: list -->
                    </div>
                </div>
            </div><!-- End: slogan-footer --><!-- Start: block-top-footer -->
            <div class="mb-1">
                <div class="container d-lg-flex">
                    <div class="d-lg-flex w-100 bg-home px-2 py-2 px-md-2 py-md-3"><!-- Start: left -->
                        <div class="col-lg-5"><!-- Start: web-contents -->
                            <div class="row g-0"><!-- Start: contens item hỗ trợ kh -->
                                <div class="col-6 col-lg-6 p-2">
                                    <div class="d-flex flex-column h-100"><!-- Start: group title -->
                                        <h4 class="title-footer text-uppercase">Hỗ trợ khách hàng</h4><!-- End: group title --><!-- Start: list contents by group -->
                                        <div><!-- Start: item -->
                                            <div v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id">
                                                <div v-if="webContentsVeHasaki.groupWebContent==='Hỗ Trợ Khách Hàng'"><nuxt-link-locale class="text-decoration-none pointer" aria-label="xem chi tiết thông tin hỗ trợ khách hàng" :to="'/ho-tro/chi-tiet/'+webContentsVeHasaki.slug">
                                                        <div class="txt-link-footer"><span>{{webContentsVeHasaki.title}}</span></div>
                                                    </nuxt-link-locale></div>
                                            </div><!-- End: item -->
                                        </div><!-- End: list contents by group -->
                                    </div>
                                </div><!-- End: contens item hỗ trợ kh --><!-- Start: contens item về hasaki -->
                                <div class="col-6 col-lg-6 p-2">
                                    <div class="d-flex flex-column h-100"><!-- Start: group title -->
                                        <h4 class="title-footer text-uppercase">Về chúng tôi</h4><!-- End: group title --><!-- Start: list contents by group -->
                                        <div><!-- Start: item -->
                                            <div v-for="(webContentsVeHasaki,index) in webContentsVeHasakis" v-bind:key="webContentsVeHasaki._id">
                                                <div v-if="webContentsVeHasaki.groupWebContent==='Về Chúng Tôi'"><nuxt-link-locale class="text-decoration-none pointer" aria-label="xem chi tiết thông tin về chúng tôi" :to="'/ho-tro/chi-tiet/'+webContentsVeHasaki.slug">
                                                        <div class="txt-link-footer"><span>{{webContentsVeHasaki.title}}</span></div>
                                                    </nuxt-link-locale></div>
                                            </div><!-- End: item -->
                                        </div><!-- End: list contents by group -->
                                    </div>
                                </div><!-- End: contens item về hasaki -->
                            </div><!-- End: web-contents -->
                        </div><!-- End: left -->
                        <div class="col-12 col-lg-3 d-flex flex-row">
                            <div class="d-flex flex-column h-100 w-100 p-2 mb-2 mb-md-0">
                                <h4 class="title-footer text-uppercase">Hợp tác và liên kết test</h4><a class="text-decoration-none text-white txt-link-footer" aria-label="facebook của chúng tôi" :href="websiteConfig.fanpage"><span class="d-flex">Website</span></a><nuxt-link-locale class="text-decoration-none pointer txt-link-footer" to="/ho-tro" aria-label="cẩm nang hỗ trợ khách hàng"><span class="w-100 d-flex">Cẩm nang</span></nuxt-link-locale>
                                <div class="row g-0 w-100">
                                    <div class="col-6 col-md-12">
                                        <div class="d-flex align-items-center py-0 py-md-2 pt-1 h-100"><nuxt-link-locale class="pointer me-2" aria-label="facebook của chúng tôi" :to="websiteConfig.fanpage"><img src="/static/cosmeticscz/fb.png" alt="logo"></nuxt-link-locale><nuxt-link-locale class="pointer me-2" aria-label="Tiktok của chúng tôi" :to="websiteConfig.tiktok"><img src="/static/cosmeticscz/icon_tiktok.png" alt="logo"></nuxt-link-locale><nuxt-link-locale class="pointer me-2" aria-label="Instagram của chúng tôi" :to="websiteConfig.instagram"><img src="/static/cosmeticscz/instagram.png" alt="logo"></nuxt-link-locale></div>
                                    </div>
                                    <div class="col-6 col-md-12">
                                        <div class="d-flex flex-column justify-content-center py-0 py-md-2 pt-1 h-100">
                                            <h4 class="title-footer text-uppercase">THANH TOÁN</h4><!-- Start: icon -->
                                            <div><img class="me-2" src="/static/cosmeticscz/mastercard.png" alt="logo"><img class="me-2" src="/static/cosmeticscz/visa.png" alt="logo"><img class="me-2" src="/static/cosmeticscz/atm.png" alt="logo"></div><!-- End: icon -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!-- Start: right -->
                        <div class="col-lg-4 ps-1">
                            <div class="d-flex h-100"><a class="a-decoration-none" target="_blank" aria-label="google maps trụ sở chính" :href="websiteConfig.mediaItemGoogleMaps.itemLink"><img class="img-fluid w-100" alt="ảnh bản đồ" :src="websiteConfig.mediaItemGoogleMaps.itemImage"></a></div>
                        </div><!-- End: right -->
                    </div>
                </div>
            </div><!-- End: block-top-footer --><!-- Start: footer-main -->
            <div class="mb-3" v-if="$device.isDesktopOrTablet">
                <div class="container">
                    <div class="row"><!-- Start: content -->
                        <div class="col-12 col-lg-8">
                            <div><!-- Start: content -->
                                <div v-if="websiteConfig.thongTinSoHuu!==''">
                                    <div>
                                        <div class="d-flex justify-content-center py-2" :class="{'seo-content-active':seeMore===true,'seo-content':seeMore!==true}"><span v-html="websiteConfig.thongTinSoHuu"></span></div>
                                    </div>
                                    <div class="d-flex flex-row justify-content-center">
                                        <div class="p-2 pointer btn-seemore" @click="seeMore=!seeMore">
                                            <h5 class="pm-0 fs-14" v-if="seeMore===false">Xem thêm</h5>
                                            <h5 class="pm-0 fs-14" v-if="seeMore===true">Thu nhỏ</h5>
                                        </div>
                                    </div>
                                </div><!-- End: content -->
                            </div>
                        </div><!-- End: content -->
                        <div class="col-lg-4 pb-4">
                            <div class="h-100" v-if="$device.isDesktopOrTablet"><!-- Start: chứng nhận D-NONE -->
                                <div class="flex-column mb-3 d-none"><span class="pe-3">Được chứng nhận</span><img class="img-fluid img-certification" src="/static/cosmeticscz/2.png" alt="chứng nhận bộ công thương"></div><!-- End: chứng nhận D-NONE --><!-- Start: top-tìm -kiếm -->
                                <div class="d-sm-none d-lg-block"><span style="font-weight:500">TOP TÌM KIẾM</span>
                                    <div class="d-flex flex-wrap mt-2 pointer">
                                        <div class="mb-3" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="danh mục nổi bật" :to="'/danh-muc/'+groupProductLv1.slug"><span class="me-2 py-1 px-2 top-seach-bottom">{{groupProductLv1.name}}</span></nuxt-link-locale></div>
                                    </div>
                                </div><!-- End: top-tìm -kiếm -->
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- End: footer-main -->
        </footer>
        <div :class="{'d-none':route.path==='/danh-muc'}" v-if="$device.isDesktop">
            <div class="container">
                <div class="mb-2 p-1"><span class="text-uppercase" style="font-weight:500">{{$t('danhMucSanPham')}}</span></div>
                <div class="row d-flex"><!-- Start: cột 1 -->
                    <div class="col-lg-3 mb-3" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id">
                        <div>
                            <div class="p-1"><span class="text-capitalize">{{groupProductLv1.name}}</span></div>
                            <div class="d-flex flex-wrap">
                                <div class="pointer" v-for="(groupProductLv2,index) in groupProductLv2s" v-bind:key="groupProductLv2._id"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="danh mục cấp 2" :to="'/danh-muc/'+groupProductLv2.slug"><span class="category-footer" v-if="groupProductLv1._id===groupProductLv2.groupProduct">{{groupProductLv2.name}}</span></nuxt-link-locale></div>
                            </div>
                        </div>
                    </div><!-- End: cột 1 -->
                </div>
            </div>
        </div>
        <div class="navigation-cushion" v-if="$device.isMobile"></div><!-- Start: mes -->
        <div>
            <div class="fixed-buttom img-logo-chat pointer"><a class="a-decoration-none" aria-label="facebook" :href="websiteConfig.fanpage"><img class="img-fluid ani-zoom-in-blur" src="/static/cosmeticscz/messenger.png" alt="icon-mesenger"></a></div>
        </div><!-- End: mes --><!-- Start: mobile-navigation -->
        <div class="navigation w-100 fixed-bottom p-1" v-if="$device.isMobile">
            <div class="w-100 h-100 body">
                <div class="row g-0 h-100 w-100">
                    <div class="col">
                        <div class="h-100 navigation-item py-1 px-1"><nuxt-link-locale class="a-decoration-none" to="/" aria-label="trang chủ">
                                <div class="d-flex flex-column justify-content-center align-items-center item"><i class="icon-home"></i><span>Trang chủ</span></div>
                            </nuxt-link-locale></div>
                    </div>
                    <div class="col">
                        <div class="h-100 navigation-item py-1 px-1">
                            <div class="d-flex flex-column justify-content-center align-items-center item" @click="userGlobalState.showmainmenugroupproduct=true"><i class="icon-badge"></i><span>Danh mục</span></div>
                        </div>
                    </div>
                    <div class="col"><nuxt-link-locale class="a-decoration-none" aria-label="liên hệ qua số điện thoại" :to="'tel:'+websiteConfig.soDienThoai">
                            <div class="navigation-item-center py-1 px-1">
                                <div class="d-flex flex-column justify-content-center align-items-center item" @click="showmenugoc=true"><i class="icon-call-out"></i><span>Liên hệ</span></div>
                            </div>
                        </nuxt-link-locale></div>
                    <div class="col">
                        <div class="h-100 navigation-item py-1 px-1">
                            <div class="d-flex flex-column justify-content-center align-items-center item" @click="div43d9480c4d66c99e16de3816df8d78b9f4f91517click"><i class="icon-user-female"></i><span>Cá nhân</span></div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="h-100 navigation-item py-1 px-1">
                            <div class="d-flex flex-column justify-content-center align-items-center item" @click="showmenugoc=true"><i class="icon-menu"></i><span>Menu</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- End: mobile-navigation -->
    </section><client-only @click="showLogin=true"><b-modal role="dialog" tabindex="-1" id="modal-1" v-model="showlanguage" hide-footer hide-header centered hide-header-close>
            <div @click="showlanguage=false">
                <div class="d-flex flex-column justify-content-center align-items-center select-language p-2">
                    <p class="pm-0">Chọn ngôn ngữ</p>
                    <p class="pm-0">(Zvolte jazyk)</p>
                </div>
                <div>										<!-- Start: vn-toogle Dev Khải ghi đè -->
                    <div class="d-flex justify-content-between align-items-center p-2 pointer hover-language" @click="() => { setLocale('vi'); userGlobalState.language = 'vi' }">
                        <div><span>Tiếng Việt</span></div>
                        <div class="img-flag"><img class="img-fluid w-100" src="/static/cosmeticscz/🇻🇳.png" alt="Cờ Việt Nam"></div>
                    </div><!-- End: vn-toogle --><!-- Start: cz-toogle id:dngiGUtaAR3k1UoqHdf5Kv-debug-component-ldr -->
                    <div class="undefined dngiGUtaAR3k1UoqHdf5Kv-preview-css-ldr d-flex justify-content-between align-items-center p-2 pointer hover-language" @click="() => { setLocale('cz'); userGlobalState.language = 'cz' }">
                        <div><span>čeština</span></div>
                        <div class="img-flag"><img class="img-fluid w-100" src="/static/cosmeticscz/🇨🇿.png" alt="Cờ Czech"></div>
                    </div><!-- End: cz-toogle id:dngiGUtaAR3k1UoqHdf5Kv-debug-component-ldr -->
                                        <!-- Start: vn-toogle Dev Khải ghi đè END-->

                </div>
            </div>
        </b-modal><!-- Start: show login register --><b-modal role="dialog" tabindex="-1" id="modal-2" v-model="userGlobalState.isshowloginform" hide-footer hide-header hide-header-close centered><!-- Start: register -->
            <div v-if="showLoginLogout==='register'"><!-- Start: title top -->
                <div class="\">
                    <div class="py-2">
                        <h5>{{$t('dangKyTaiKhoan')}}</h5>
                    </div>
                </div><!-- End: title top --><!-- Start: email -->
                <div class="py-2"><label class="form-label">{{$t('email')}}</label><input type="text" class="w-100 p-2" placeholder="Nhập email đăng ký" v-model="tempemail"></div><!-- End: email --><!-- Start: mật khẩu -->
                <div class="py-2"><label class="form-label">{{$t('matKhau')}}</label><input type="password" class="w-100 py-2" v-model="register.password"></div><!-- End: mật khẩu --><!-- Start: Họ tên -->
                <div class="py-2"><label class="form-label">{{$t('hoVaTen')}}</label><input type="text" class="w-100 p-2" placeholder="Họ và tên" v-model="register.fullName"></div><!-- End: Họ tên --><!-- Start: check -->
                <div class="py-2"><b-form-checkbox-group class="input-group"></b-form-checkbox-group></div><!-- End: check --><!-- Start: btn đăng ký -->
                <div class="mb-2 py-2"><button class="btn btn-primary w-100 btn-signup-lg" type="button" aria-label="Đăng nhập" @click="()=>{let stepBreak=0;;if( (tempemail==='' || register.password==='' || register.fullName==='')&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Vui lòng nhập đầy đủ thông tin',variant:'danger'}})};if( tempemail==='' || register.password==='' || register.fullName==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){register.email=toLower(tempemail)};if( (!stepBreak||stepBreak===4) ){signupGraphRegister()}}">ĐĂNG KÝ</button></div><!-- End: btn đăng ký -->
                <div class="d-flex p-2"><!-- Start: bạn đã có tài khoản -->
                    <div class="d-flex align-items-center me-3"><span>{{$t('banDaCoTaiKhoan')}}</span><span class="ms-2 btn-signup pointer" @click="showLoginLogout='login'">Đăng nhập ngay</span></div><!-- End: bạn đã có tài khoản -->
                </div>
            </div><!-- End: register --><!-- Start: login -->
            <div v-if="showLoginLogout==='login'"><!-- Start: title top -->
                <div>
                    <div>
                        <h5>{{$t('nhapThongTinDangNhap')}}</h5>
                    </div>
                </div><!-- End: title top --><!-- Start: email -->
                <div class="py-2"><label class="form-label">{{$t('email')}}</label><input type="email" class="w-100 p-2 loggin-email" placeholder="Nhập email hoặc số điện thoại" v-model="temploginemail"></div><!-- End: email --><!-- Start: mật khẩu -->
                <div class="py-2"><label class="form-label">{{$t('matKhau')}}</label><input type="password" class="p-2 w-100 borfer" v-model="user.password"></div><!-- End: mật khẩu -->
                <div class="pt-2"><button class="btn btn-primary w-100 btn-signup-lg mb-1" type="button" aria-label="Đăng nhập" @click="buttoneb9cb39ef223cbac75d9ba3f5be0232228e41a23click">{{$t('dangNhap')}}</button>
                    <div class="d-flex justify-content-end"><span class="justify-content-end forgot-password p-1" @click="showLoginLogout='forgot'">Quên mật khẩu?</span></div>
                </div>
                <div class="d-flex py-2"><!-- Start: bạn đã có tài khoản -->
                    <div class="d-flex align-items-center me-3"><span>Bạn chưa có tài khoản?</span><span class="ms-2 btn-signup pointer" @click="showLoginLogout='register'">Đăng ký ngay</span></div><!-- End: bạn đã có tài khoản -->
                </div>
            </div><!-- End: login --><!-- Start: forgot -->
            <div v-if="showLoginLogout==='forgot'"><!-- Start: title top -->
                <div>
                    <div>
                        <h5 class="mb-0">Quên mật khẩu</h5>
                    </div><!-- Start: FIND ------- -->
                    <div v-if="userByEmail._id===undefined"><!-- Start: email -->
                        <div class="py-2"><label class="form-label mb-1 w-100">{{$t('email')}}</label><input type="email" class="w-100 p-2 loggin-email required" placeholder="Nhập email tài khoản" v-model="emailUserFind"></div><!-- End: email --><!-- Start: số điện thoại -->
                        <div class="py-2"><label class="form-label mb-1">Số điện thoại:</label><input type="text" class="p-2 w-100 borfer required" placeholder="Số điện thoại tài khoản" v-model="phoneUserFind"></div><!-- End: số điện thoại -->
                        <div class="pt-2"><button class="btn btn-primary w-100 btn-signup-lg" type="button" aria-label="tìm tài khoản" @click="button0cb4ecc38eaf3ae66737a71527971767d1acaaf4click">Tìm tài khoản</button></div><!-- Start: result -->
                        <div class="py-1">{{userByEmail.fullName}}</div><!-- End: result -->
                    </div><!-- End: FIND ------- --><!-- Start: CHANGE --------- -->
                    <div v-if="userByEmail._id!==undefined"><!-- Start: số điện thoại -->
                        <div class="py-2"><label class="form-label mb-1">Chào&nbsp;<span class="color-main fw-500">{{userByEmail.fullName}}</span>, hãy nhập mật khẩu mới</label><input type="password" class="p-2 w-100 borfer required" placeholder="Mật khẩu mới" v-model="newpass"></div><!-- End: số điện thoại --><!-- Start: số điện thoại -->
                        <div class="py-2"><input type="password" class="p-2 w-100 borfer required" placeholder="Xác nhận mật khẩu mới" v-model="renewpass"></div><!-- End: số điện thoại --><button class="btn btn-primary w-100 btn-signup-lg" type="button" aria-label="Đổi mật khẩu" @click="()=>{let stepBreak=0;;if( (newpass!==renewpass || newpass==='' || renewpass==='')&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'',body:'Mật khẩu và xác nhận không chính xác',variant:'danger'}})};if( newpass==='' || renewpass==='' || newpass!==renewpass ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){userUpdateForgotPassword._id=userByEmail?._id};if( (!stepBreak||stepBreak===4) ){userUpdateForgotPassword.password=newpass};if( (!stepBreak||stepBreak===5) ){capNhatQuenMatKhauUserupdateforgotpassword(userByEmail._id,)}}">Lưu mật khẩu mới</button>
                    </div><!-- End: CHANGE --------- -->
                </div><!-- End: title top -->
                <div class="d-flex py-2"><!-- Start: bạn đã có tài khoản -->
                    <div class="d-flex justify-content-between align-items-center w-100"><span class="btn-signup pointer" @click="showLoginLogout='login'">Đăng nhập</span><span class="btn-signup pointer" @click="showLoginLogout='register'">Đăng ký</span></div><!-- End: bạn đã có tài khoản -->
                </div>
            </div><!-- End: forgot -->
        </b-modal><!-- End: show login register --><!-- Start: menu --><b-offcanvas placement="start" id="offcanvas-3" class="w-100 bg-opacity h-100" v-model="showmenugoc" noheader header-class="true">
            <div class="w-100 bg-opacity h-100" style="padding: 0;">
                <div class="d-flex h-100 bg-opacity w-100">
                    <div class="w-75 bg-bdy h-100" @click="showmenugoc=false"><!-- Start: chưa đăng nhập -->
                        <div class="bg-home-menu py-1" @click="showLogin=true"><!-- Start: chưa đăng nhập -->
                            <div class="d-flex align-items-center" v-if="userGlobalState.userId===''"><!-- Start: icon -->
                                <div class="py-3 img-top-logo">
                                    <div class="img-icon"><img src="/static/cosmeticscz/icon_header_2.png"></div>
                                </div><!-- End: icon --><!-- Start: title -->
                                <div @click="userGlobalState.isshowloginform=true"><span>{{$t('dangNhapDangKy')}}</span></div><!-- End: title -->
                            </div><!-- End: chưa đăng nhập --><!-- Start: đã đăng nhập -->
                            <div class="d-flex" v-if="userGlobalState.userId!==''"><!-- Start: icon -->
                                <div class="py-3 img-top-logo">
                                    <div class="img-icon"><img src="/static/cosmeticscz/icon_header_2.png" alt="Tài khoản"></div>
                                </div><!-- End: icon --><!-- Start: title -->
                                <div class="d-flex flex-row align-items-center"><span>Chào&nbsp;<span>{{userGetMe.fullName}}</span>&nbsp;!</span></div><!-- End: title -->
                            </div><!-- End: đã đăng nhập -->
                        </div><!-- End: chưa đăng nhập --><!-- Start: bottom -->
                        <div class="mb-2 px-2 bg-white"><!-- Start: ngôn ngữ -->
                            <div class="py-2 border-bottom" @click="showlanguage=true">
                                <div class="d-flex px-2 py-1">
                                    <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_12.png"></div><span class="ms-2">{{$t('ngonNgu')}}</span>
                                </div>
                            </div><!-- End: ngôn ngữ --><!-- Start: trang chủ -->
                            <div class="py-2 border-bottom"><!-- Start: trang chủ --><a class="text-decoration-none text-dark" href="/" aria-label="trang chủ">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_1 (1).png"></div><span class="ms-2">{{$t('trangChu')}}</span>
                                    </div>
                                </a><!-- End: trang chủ --></div><!-- End: trang chủ --><!-- Start: danh mục -->
                            <div class="py-2 border-bottom" @click="userGlobalState.showmainmenugroupproduct=!userGlobalState.showmainmenugroupproduct">
                                <div class="d-flex px-2 py-1">
                                    <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_2.png"></div><span class="ms-2">{{$t('danhMuc')}}</span>
                                </div>
                            </div><!-- End: danh mục --><nuxt-link-locale class="a-decoration-none" to="/gio-hang" aria-label="giỏ hàng">
                                <div class="d-flex justify-content-between align-items-center py-2 border-bottom">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_3.png"></div><span class="ms-2">{{$t('gioHang')}}</span>
                                    </div>
                                    <div class="cart-almount-menu"><span>{{productCart?.length}}</span></div>
                                </div>
                            </nuxt-link-locale><!-- Start: sản phẩm bán chạy -->
                            <div class="py-2 border-bottom"><!-- Start: hotline --><a class="text-decoration-none text-dark" href="/san-pham-ban-chay" aria-label="bán chạy">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_8.png"></div><span class="ms-2">{{$t('banChay')}}</span>
                                    </div>
                                </a><!-- End: hotline --></div><!-- End: sản phẩm bán chạy --><!-- Start: hot deals -->
                            <div class="py-2 border-bottom"><!-- Start: hot deals --><a class="text-decoration-none text-dark" href="/bo-suu-tap/hot-deals" aria-label="hot deals">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/promotion.png"></div><span class="ms-2">{{$t('hotDeals')}}</span>
                                    </div>
                                </a><!-- End: hot deals --></div><!-- End: hot deals --><!-- Start: thương hiệu -->
                            <div class="py-2 border-bottom"><!-- Start: thương hiệu --><a class="text-decoration-none text-dark" href="/thuong-hieu" aria-label="thương hiệu">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_6.png"></div><span class="ms-2">{{$t('thuongHieu')}}</span>
                                    </div>
                                </a><!-- End: thương hiệu --></div><!-- End: thương hiệu --><!-- Start: hàng mới về -->
                            <div class="py-2 border-bottom"><!-- Start: hàng mới về --><a class="text-decoration-none text-dark" href="/hang-moi-ve" aria-label="hàng mới về">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_7.png"></div><span class="ms-2">{{$t('hangMoiVe')}}</span>
                                    </div>
                                </a><!-- End: hàng mới về --></div><!-- End: hàng mới về -->
                        </div><!-- End: bottom --><!-- Start: about account -->
                        <div class="mb-2 px-2 bg-white" v-if="(userGlobalState.userId!=='')&&(userGlobalState.userId!==undefined)&&(userGlobalState.userId!==null)"><!-- Start: kiểm tra đơn hàng -->
                            <div class="py-2 border-bottom"><nuxt-link-locale class="a-decoration-none" aria-label="Kiểm tra đơn hàng" to="/tai-khoan/don-hang">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_10.png"></div><span class="ms-2">{{$t('traCuuDonHang')}}</span>
                                    </div>
                                </nuxt-link-locale></div><!-- End: kiểm tra đơn hàng --><!-- Start: danh sách yêu thihcs -->
                            <div class="py-2 border-bottom"><nuxt-link-locale class="a-decoration-none" aria-label="Sản phẩm yêu thích" to="/tai-khoan/yeu-thich">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_11.png"></div><span class="ms-2">{{$t('sanPhamYeuThich')}}</span>
                                    </div>
                                </nuxt-link-locale></div><!-- End: danh sách yêu thihcs --><!-- Start: quản lý tài khoản -->
                            <div class="py-2 border-bottom"><nuxt-link-locale class="a-decoration-none" aria-label="quản lý tài khoản" to="/tai-khoan">
                                    <div class="d-flex px-2 py-1">
                                        <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_12.png"></div><span class="ms-2">{{$t('taiKhoanCuaBan')}}</span>
                                    </div>
                                </nuxt-link-locale></div><!-- End: quản lý tài khoản -->
                        </div><!-- End: about account --><!-- Start: about account !user -->
                        <div class="mb-2 px-2 bg-white pt-2" v-if="userGlobalState.userId==='' || userGlobalState.userId===undefined || userGlobalState.userId===null" @click="userGlobalState.isshowloginform=true"><span class="px-1 color-main fw-500">{{$t('dangNhapDeSuDung')}}</span><!-- Start: kiểm tra đơn hàng -->
                            <div class="py-2 border-bottom">
                                <div class="d-flex px-2 py-1">
                                    <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_10.png"></div><span class="ms-2">{{$t('traCuuDonHang')}}</span>
                                </div>
                            </div><!-- End: kiểm tra đơn hàng --><!-- Start: danh sách yêu thihcs -->
                            <div class="py-2 border-bottom">
                                <div class="d-flex px-2 py-1">
                                    <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_11.png"></div><span class="ms-2">{{$t('sanPhamYeuThich')}}</span>
                                </div>
                            </div><!-- End: danh sách yêu thihcs --><!-- Start: quản lý tài khoản -->
                            <div class="py-2 border-bottom">
                                <div class="d-flex px-2 py-1">
                                    <div class="img-logo-mo"><img class="img-fluid" src="/static/cosmeticscz/icon_menu_12.png"></div><span class="ms-2">{{$t('taiKhoanCuaBan')}}</span>
                                </div>
                            </div><!-- End: quản lý tài khoản -->
                        </div><!-- End: about account !user -->
                    </div>
                    <div class="w-25 h-100" @click="showmenugoc=!showmenugoc"><i class="icon ion-android-arrow-back fs-27 text-white mx-2"></i></div>
                </div>
            </div>
        </b-offcanvas><!-- End: menu -->
        
        <!-- Start: GROUP PRODUCT START HERE !!!!!!!!!!!!!!!!!!!!!!! <01> --> 
         <b-offcanvas placement="start" backdrop id="offcanvas-1" style="width: 100%;" v-model="userGlobalState.showmainmenugroupproduct" noheader>
            <div>
                <div class="h-100 bg-bdy flexible-page"><!-- Start: header -->
                    <div class="d-flex justify-content-between align-items-center bg-white primary-box-shadow p-1 menu-danh-muc-header"><span>Danh mục sản phẩm</span><i class="icon-close close-icon" @click="userGlobalState.showmainmenugroupproduct=false;showmenugoc=true"></i></div><!-- End: header --><!-- Start: 2 cột -->
                    <div class="bg-white primary-box-shadow position-relative fix-height-menu">
                        <div class="row g-0 h-100 bg-grey-default">
                            <div class="col-3"><!-- Start: cha -->
                                <div class="h-100 bg-white">
                                    <div>
                                        <div class="d-flex align-items-center" v-for="(groupProductLv1,index) in groupProductLv1s" v-bind:key="groupProductLv1._id" @click="()=>{}">
                                            <div class="block-item-cat-mo w-100 lv-1 primary-box-shadow" :class="{'show':selectedgroupproductlv1===groupProductLv1._id}" @click="()=>{selectedgroupproductlv1=groupProductLv1._id;loadDatagroupProductForMobileMenu()}">
                                                <p class="pm-0" v-if="userGlobalState.language==='vi'">{{groupProductLv1.name}}</p>
                                                <p class="pm-0" v-else>{{groupProductLv1.secondName}}</p>
                                                <!-- Start: list group lv-2 -->
                                                <div class="list-group-lv-2 bg-grey-default"><!-- Start: cha -->
                                                    <div class="h-100"><!-- Start: list -->
                                                        <div class="d-flex flex-wrap"><!-- Start: item -->
                                                            <div class="block-item-product-cat-mo p-1 primary-boder-light bg-white" v-for="(groupProductForMobileMenu,index) in groupProductForMobileMenus" v-bind:key="groupProductForMobileMenu._id" @click="userGlobalState.showmainmenugroupproduct=false">
                                                                <nuxt-link-locale class="text-decoration-none text-dark" aria-label="Danh mục cấp 2" :to="'/danh-muc/'+groupProductForMobileMenu.slug">
                                                                    <img class="img-fluid w-100" v-if="groupProductForMobileMenu.thumbnail!==null" :src="groupProductForMobileMenu.thumbnail" :alt="groupProductForMobileMenu.name">
                                                                    <img class="img-fluid" src="/static/cosmeticscz/no-image-news.png" alt="ảnh phụ" v-if="groupProductForMobileMenu.thumbnail===null">
                                                                    <div class="item-title-cat">
                                                                        <p class="mb-0" v-if="userGlobalState.language==='vi'">{{groupProductForMobileMenu.name}}</p>
                                                                        <p class="mb-0" v-else>{{groupProductForMobileMenu.secondName}}</p>
                                                                    </div>
                                                                </nuxt-link-locale></div><!-- End: item --><!-- Start: item -->
                                                            <div class="block-item-product-cat-mo p-1 primary-boder-light bg-white" @click="userGlobalState.showmainmenugroupproduct=false"><nuxt-link-locale class="text-decoration-none text-dark" aria-label="chi tiết danh mục cấp 1" :to="'/danh-muc/'+groupProductLv1.slug">
                                                                    <div class="d-flex justify-content-center align-items-center item-fake-img-cat"><i class="icon-options fs-2 color-main"></i></div>
                                                                    <div class="item-title-cat">
                                                                        <p class="mb-0">{{$t('xemTatCa')}}</p>
                                                                    </div>
                                                                </nuxt-link-locale></div><!-- End: item -->
                                                        </div><!-- End: list -->
                                                    </div><!-- End: cha -->
                                                </div><!-- End: list group lv-2 -->
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- End: cha -->
                            </div>
                        </div>
                    </div><!-- End: 2 cột -->
                </div>
            </div>
        </b-offcanvas><!-- End: GROUP PRODUCT --><!-- Start: Mobile Search --><b-offcanvas placement="top" backdrop id="offcanvas-4" class="w-100 h-100" v-model="showcanvassearchmobile">
            <div>
                <div class="h-100 flexible-page"><!-- Start: header -->
                    <div class="d-flex align-content-center primary-box-shadow p-2 menu-danh-muc-header bg-home-light part-static fixed-top"><i class="icon-close close-icon-2 div-left me-2 bg-white" @click="showcanvassearchmobile=false"></i>
                        <form class="d-flex justify-content-between align-items-center w-100 search-form-mobile" method="get" action="/search"><input class="form-control div-right mobile-text-search py-1" type="text" placeholder="Tìm sản phẩm, thương hiệu ..." autofocus="" name="keyword" v-model="keyword" @update:model-value="inputtext7b40660e80d4dc67d8050d8e0ae106fea888b0ddupdatemodelvalue"><button class="btn" type="button"><i class="icon-magnifier"></i></button></form>
                    </div><!-- End: header --><!-- Start: header-clone -->
                    <div class="d-flex align-content-center primary-box-shadow p-2 menu-danh-muc-header bg-home-light part-static mask-overlay"><i class="icon-close close-icon-2 div-left me-2 bg-white" @click="showcanvassearchmobile=false"></i></div><!-- End: header-clone --><!-- Start: result -->
                    <div class="part-dynamic bg-bdy p-2"><!-- Start: list product -->
                        <div class="mb-2 bg-white p-2"><!-- Start: item -->
                            <div v-for="(listProductSearch,index) in listProductSearchs" v-bind:key="listProductSearch._id"><!-- Start: product -->
                                <div class="d-flex" @click="div0c74aab2e86d93d27ef40f346154d7205b1399baclick(listProductSearch,index)"><nuxt-link-locale class="text-decoration-none text-dark w-100" aria-label="sản phẩm" :to="'/san-pham/'+listProductSearch.slug">
                                        <div class="d-flex p-1 hover-menu w-100">
                                            <div class="search-img"><img class="img-fluid" :src="listProductSearch.image" :alt="listProductSearch.name"></div>
                                            <div class="d-flex flex-column fs-14 p-1 search-desc">
                                                <p class="pm-0 tx-price">{{formatNumberMoney(listProductSearch.price)}}</p>
                                                <p class="pm-0">{{listProductSearch.name}}</p>
                                            </div>
                                        </div>
                                    </nuxt-link-locale></div><!-- End: product -->
                            </div><!-- End: item -->
                        </div><!-- End: list product --><!-- Start: list brands -->
                        <div class="p-2 bg-white">
                            <h6 class="mb-1">Tìm theo thương hiệu</h6>
                            <div class="p-1">
                                <div class="row g-0 g-2">
                                    <div class="col-4" v-for="(searchProductBrands,index) in searchProductBrandss" v-bind:key="searchProductBrands._id" @click="column0c74aab2e86d93d27ef40f346154d7205b1399baclick(searchProductBrands,index)"><nuxt-link-locale class="a-decoration-none" aria-label="thương hiệu tìm kiếm" :to="'/thuong-hieu/'+searchProductBrands.slug">
                                            <div class="search-brand"><img class="img-fluid w-100" :src="searchProductBrands.banner" :alt="searchProductBrands.name"></div>
                                        </nuxt-link-locale></div>
                                </div>
                            </div>
                        </div><!-- End: list brands -->
                    </div><!-- End: result -->
                </div>
            </div>
        </b-offcanvas><!-- End: Mobile Search --><!-- Start: Logout --><b-modal role="dialog" tabindex="-1" id="modal-3" v-model="userGlobalState.showlogoutmodal" hide-footer hide-header hide-header-close centered><!-- Start: title top -->
            <div>
                <div>
                    <h5>Bạn muốn đăng xuất ?</h5>
                </div>
            </div><!-- End: title top -->
            <div class="d-flex justify-content-between align-items-center mb-2 py-2 w-100">
                <div class="w-50 pe-1"><button class="btn btn-primary w-100 btn-signup-lg" type="button" aria-label="Đăng xuất" @click="button9afa3282c5191ed44c8f9f275515eaa6bfcf054eclick">Đăng xuất</button></div>
                <div class="w-50 ps-1"><button class="btn btn-primary w-100 btn-logout-lg" type="button" aria-label="Huỷ" @click="userGlobalState.showlogoutmodal=true">Huỷ</button></div>
            </div>
        </b-modal><!-- End: Logout --></client-only>
</div>
</template><script setup>
    import { computed,watch } from 'vue';
    import {formatNumberMoney,} from '../util';
    import {onMounted,} from 'vue';
    import toLower from 'lodash/toLower.js';
    // DEV KHẢI GHI ĐÈ IMPORT SETLOCALE
    const { locale } = useI18n();
    const config = useRuntimeConfig()
    const userGlobalState = useUserGlobalState();
            


    
    

            


    const {   setLocale } = useI18n();


    

    //replaceMome
    
    


    


    const $device = useDevice();
    const { isMobile,isDesktop } = useDevice();
    const route = useRoute();

    
    
    const toast = useToast();
    
    const productCart = useCookie('productCart');
    productCart.value = productCart.value||[];

    
    const checkshowlanguage = useCookie('checkshowlanguage');
    checkshowlanguage.value = checkshowlanguage.value||false;

    


    
let user= ref({email: "",
    password: ""});

let register= ref({email: "",
    password: "",
    fullName: ""});
let showhomemenuoflayout= ref(false);
    let selectedId= ref("");
    let selectedLabel= ref("");
    let showmenu= ref("");
    let showlanguage= ref(false);
    let showmenugoc= ref(false);
    let idGroupLv1= ref("");
    let showFilter= ref(false);
    let signin= ref(false);
    let showLoginLogout= ref("login");
    let showLogin= ref(false);
    let seeMore= ref(false);
    let keyword= ref("");
    let key= ref("asdfg");
    let idgrouplv2= ref("");
    let showmenugroupproduct= ref(false);
    let selectedgroupproductlv1= ref("");
    let showcanvassearchmobile= ref(false);
    let tempemail= ref("");
    let temploginemail= ref("");
    let idUserForgotpassword= ref("");
    let emailUserFind= ref("");
    let phoneUserFind= ref("");
    let newpass= ref("");
    let renewpass= ref("");;
 let selectedMenu =ref({id: "",
    groupMenuLink: [],
label: "",
    selectedLinkLv2: ""});
let thongTinLienHe= ref({email: ""});

let userLogoutAction= ref({email: ""});

let userUpdateForgotPassword= ref({password: "",
    _id: ""});










    //   variables
    if(process.env.NODE_ENV==='development'){

    }









    // computed
    

    //attributesCal 0

    


        // computed





        const clearTypeName = (api)=>{
            const funF = (obj)=>{
                if(obj){
                    console.log('obj')
                    Object.keys(obj).forEach((key)=>{
                        if(key==="__typename"){
                            delete obj.__typename;
                        } else if(Array.isArray(obj[key])){
                            obj[key].forEach((item)=>{
                                if(item)
                                    funF(item);
                            });
                        } else if(typeof (obj[key]) ==="object"&&(obj[key])){
                            if(obj[key])
                                funF(obj[key]);
                        }
                    });}
            };
            funF(api);
            return api;
        };



        //methods


    

// mutation

        const logoutGraphUserlogoutaction = async( )=>{
            try {
                
                   let ValuserLogoutAction = JSON.parse(JSON.stringify({})) 
                
                
                
                
                  ValuserLogoutAction = clearTypeName(ValuserLogoutAction);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 logoutGraphUser($user: userLogoutgraphInput!){
        logoutGraphUser(user:$user)
                
        

}`,
                        variables: {user:ValuserLogoutAction }}
                });
                
                if(data.value.data.logoutGraphUser){
                    toast?.show({props:{title:'',body:'Đã Logout',variant:'success'}});reload()
                }
                        


                {

                }
                if(data.value.data.logoutGraphUser){
                    data.value.data.logoutGraphUser.value = JSON.parse(JSON.stringify(userLogoutAction.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const createThongtinlienhe = async( )=>{
            try {
                
                   let ValthongTinLienHe = JSON.parse(JSON.stringify(thongTinLienHe.value)) 
                
                
                
                
                  ValthongTinLienHe = clearTypeName(ValthongTinLienHe);
                const {data} =  await useFetch(config.public.siteUrl+'/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 createContact($contact: contactCreateInput!){
        createContact(contact:$contact)
                {email _id }
        

}`,
                        variables: {contact:ValthongTinLienHe }}
                });
                
                if(data.value.data.createContact){
                    toast?.show({props:{title:'Thông báo',body:'Gửi thông tin thành công',variant:'success'}});thongTinLienHe.value.email=''
                }
                        


                {

                }
                if(data.value.data.createContact){
                    data.value.data.createContact.value = JSON.parse(JSON.stringify(thongTinLienHe.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const loginGraphUser = async( )=>{
            try {
                
                   let Valuser = JSON.parse(JSON.stringify(user.value)) 
                
                
                
                
                  Valuser = clearTypeName(Valuser);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 loginGraphUser($user: userLogingraphInput!){
        loginGraphUser(user:$user)
                
        

}`,
                        variables: {user:Valuser }}
                });
                
                if(data.value.data.loginGraphUser){
                    toast?.show({props:{title:'Đã đăng nhập',body:'',variant:'success'}});userGlobalState.value.userId=data.value.data.loginGraphUser._id;loadDatauserGetMe();router.push({path:'/tai-khoan/'})
                }
                        
                else{
                    toast?.show({props:{title:'Đăng nhập lỗi',body:'',variant:'success'}})
                }
                


                {

                }
                if(data.value.data.loginGraphUser){
                    data.value.data.loginGraphUser.value = JSON.parse(JSON.stringify(user.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const signupGraphRegister = async( )=>{
            try {
                
                   let Valregister = JSON.parse(JSON.stringify(register.value)) 
                
                
                
                
                  Valregister = clearTypeName(Valregister);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 signupGraphUser($user: userSignupgraphInput!){
        signupGraphUser(user:$user)
                {email password fullName _id }
        

}`,
                        variables: {user:Valregister }}
                });
                
                if(data.value.data.signupGraphUser){
                    buttonf7e3cef73fc44e9054110eee9face29daf299738click(data.value.data.signupGraphUser)
                }
                        
                else{
                    toast?.show({props:{title:'',body:'Email này đã được sử dụng, vui lòng dùng Email khác',variant:'danger'}})
                }
                


                {

                }
                if(data.value.data.signupGraphUser){
                    data.value.data.signupGraphUser.value = JSON.parse(JSON.stringify(register.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const loginGraphUser1 = async( )=>{
            try {
                
                   let Valuser = JSON.parse(JSON.stringify(user.value)) 
                
                
                
                
                  Valuser = clearTypeName(Valuser);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 loginGraphUser($user: userLogingraphInput!){
        loginGraphUser(user:$user)
                
        

}`,
                        variables: {user:Valuser }}
                });
                
                if(data.value.data.loginGraphUser){
                    toast?.show({props:{title:'Thông báo',body:'Đăng nhập thành công',variant:'success'}});userGlobalState.value.isshowloginform=false;userGlobalState.value.userId=data.value.data.loginGraphUser._id;loadDatauserGetMe();reload()
                }
                        
                else{
                    toast?.show({props:{title:'Thông báo',body:'Thông tin đăng nhập không đúng',variant:'danger'}})
                }
                


                {

                }
                if(data.value.data.loginGraphUser){
                    data.value.data.loginGraphUser.value = JSON.parse(JSON.stringify(user.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const loginGraphUser2 = async( )=>{
            try {
                
                   let Valuser = JSON.parse(JSON.stringify(user.value)) 
                
                
                
                
                  Valuser = clearTypeName(Valuser);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 loginGraphUser($user: userLogingraphInput!){
        loginGraphUser(user:$user)
                
        

}`,
                        variables: {user:Valuser }}
                });
                
                if(data.value.data.loginGraphUser){
                    toast?.show({props:{title:'',body:'Đã login với mật khẩu mới',variant:'success'}});reload()
                }
                        
                else{
                    toast?.show({props:{title:'',body:'Lỗi',variant:'danger'}})
                }
                


                {

                }
                if(data.value.data.loginGraphUser){
                    data.value.data.loginGraphUser.value = JSON.parse(JSON.stringify(user.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const capNhatQuenMatKhauUserupdateforgotpassword = async( _id)=>{
            try {
                
                   let ValuserUpdateForgotPassword = JSON.parse(JSON.stringify(userUpdateForgotPassword.value)) 
                delete ValuserUpdateForgotPassword._id
                delete ValuserUpdateForgotPassword.__typename
                delete ValuserUpdateForgotPassword.createdAt
                delete ValuserUpdateForgotPassword.updatedAt
                  ValuserUpdateForgotPassword = clearTypeName(ValuserUpdateForgotPassword);
                const {data} =  await useFetch(config.public.siteUrl+'/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
capNhatQuenMatKhauUser($_id: String!,$user: userCapnhatquenmatkhauInput!){
        capNhatQuenMatKhauUser(_id:$_id,user:$user)
                {_id password _id }
        

}`,
                        variables: {user:ValuserUpdateForgotPassword,_id:_id }}
                });
                
                if(data.value.data.capNhatQuenMatKhauUser){
                    buttonbaf7c940c9e3dafdab3249fee4b977f3800d9d5cclick(data.value.data.capNhatQuenMatKhauUser)
                }
                        


                {

                }
                if(data.value.data.capNhatQuenMatKhauUser){
                    data.value.data.capNhatQuenMatKhauUser.value = JSON.parse(JSON.stringify(userUpdateForgotPassword.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        const logoutGraphUserlogoutaction1 = async( )=>{
            try {
                
                   let ValuserLogoutAction = JSON.parse(JSON.stringify({})) 
                
                
                
                
                  ValuserLogoutAction = clearTypeName(ValuserLogoutAction);
                const {data} =  await useFetch('/graphql',{

                    method: 'post',
                    body:{
                        query: `mutation 
 logoutGraphUser($user: userLogoutgraphInput!){
        logoutGraphUser(user:$user)
                
        

}`,
                        variables: {user:ValuserLogoutAction }}
                });
                
                if(data.value.data.logoutGraphUser){
                    toast?.show({props:{title:'',body:'Đã Logout',variant:'success'}});reload()
                }
                        


                {

                }
                if(data.value.data.logoutGraphUser){
                    data.value.data.logoutGraphUser.value = JSON.parse(JSON.stringify(userLogoutAction.value))
                }

            } catch (e) {
                console.error(e)
            }

        }
        

    
    const router = useRouter();
    


    
    const loadDatalistProductSearch = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablelistProductSearchs={limit: 5, query :{status:{eq:true},}};
if( keyword.value!='' || keyword.value!==''){if( keyword.value===null || keyword.value==='' || keyword.value===undefined){variablelistProductSearchs.query.name = {eq:"ádasdasdasdasd"}
                              
}}if( keyword.value!='' || keyword.value!==''){
                                    if(keyword.value&&keyword.value!==''){
                                    variablelistProductSearchs.query.searchText = {search: keyword.value  } 
                                    
                                    }
                              
}
const resultlistProductSearchstemp = await useAsyncQuery( gql` query    
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProductSearchs:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug image price 
        }

}`,variablelistProductSearchs);
if(resultlistProductSearchstemp.data&&resultlistProductSearchstemp.data.value?.listProductSearchs){
listProductSearchs.value = resultlistProductSearchstemp.data.value?.listProductSearchs};
;

    };

    
    const loadDatasearchProductBrands = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablesearchProductBrandss={query :{status:{eq:true},}};

                                    if(keyword.value&&keyword.value!==''){
                                    variablesearchProductBrandss.query.name = {regex: keyword.value  } 
                                    
                                    }
                              
if( keyword.value===undefined || keyword.value==='' || keyword.value===null){variablesearchProductBrandss.query.name = {eq:"kjhkjhkjhkdjhfkdhg"}
                              
}
const resultsearchProductBrandsstemp = await useAsyncQuery( gql` query    
 productBrandss($query: productBrandsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     searchProductBrandss:  productBrandss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug banner status 
        }

}`,variablesearchProductBrandss);
if(resultsearchProductBrandsstemp.data&&resultsearchProductBrandsstemp.data.value?.searchProductBrandss){
searchProductBrandss.value = resultsearchProductBrandsstemp.data.value?.searchProductBrandss};
;

    };

    
    const loadDatauserGetMe = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variableme={};

const resultuserGetMetemp = await useAsyncQuery( gql` query {me
     {
                _id email fullName 
        }
     }
`,variableme);
if(resultuserGetMetemp.data&&resultuserGetMetemp.data.value?.me){
userGetMe.value = {...resultuserGetMetemp.data.value?.me}};
;

    };

    
    const loadDatauserByEmail = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablelay1DuLieuTheoEmail={query :{}};
if( emailUserFind.value!==null){
                                
                                    variablelay1DuLieuTheoEmail.query.email = {eq: toLower(emailUserFind.value,"")  } 
                                    
                                    
                              
}if( emailUserFind.value!==null){
                                
                                    variablelay1DuLieuTheoEmail.query.phoneNumber = {eq: phoneUserFind.value  } 
                                    
                                    
                              
}
const resultuserByEmailtemp = await useAsyncQuery( gql` query    
 lay1DuLieuTheoEmailUser($query: userLay1dulieutheoemailInput,$limit:Float,$skip:Float,$sort:JSONObject){
     lay1DuLieuTheoEmail:  lay1DuLieuTheoEmailUser(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id fullName email phoneNumber password 
        }

}`,variablelay1DuLieuTheoEmail);
if(resultuserByEmailtemp.data&&resultuserByEmailtemp.data.value?.lay1DuLieuTheoEmail){
userByEmail.value = {...resultuserByEmailtemp.data.value?.lay1DuLieuTheoEmail}};
;

    };

    
    const loadDatagroupProductForMobileMenu = async function(){
         await new Promise((re)=>{setTimeout(()=>{re(true)},70)});

let variablegroupProductForMobileMenus={sort :{orderNumber:1}, query :{status:{eq:true},}};

                                    if(selectedgroupproductlv1.value&&selectedgroupproductlv1.value!==''){
                                    variablegroupProductForMobileMenus.query.groupProduct = {in: selectedgroupproductlv1.value  } 
                                    
                                    }
                              

const resultgroupProductForMobileMenustemp = await useAsyncQuery( gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductForMobileMenus:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug thumbnail groupProduct status secondName
        }

}`,variablegroupProductForMobileMenus);
if(resultgroupProductForMobileMenustemp.data&&resultgroupProductForMobileMenustemp.data.value?.groupProductForMobileMenus){
groupProductForMobileMenus.value = resultgroupProductForMobileMenustemp.data.value?.groupProductForMobileMenus};
;

    };

    const inputtext7b40660e80d4dc67d8050d8e0ae106fea888b0ddupdatemodelvalue = async function(){
        await loadDatalistProductSearch();await loadDatasearchProductBrands();
    };
    const imageda5a33991d426f07d5ee57addc75a8f9c53cec9cclick = async function(){
        setLocale('vi');
    };
    const image9f919bf306562acd1aed951ba691078385243a71click = async function(){
        setLocale('cz');
    };
    const div3a44be39293091f259df8fbef40971acfc3b93c6click = async function(){
        userLogoutAction.value.email=userGetMe.value?.email;await logoutGraphUserlogoutaction();userGlobalState.value.userId='';reload();
    };
    const div43d9480c4d66c99e16de3816df8d78b9f4f91517click = async function(){
          await new Promise((re)=>{
                    setTimeout(()=>{re(true);},10);
                });;if( userGlobalState.value.userId==='' || userGlobalState.value.userId===undefined ){userGlobalState.value.isshowloginform=true};if( (userGlobalState.value.userId!=='')&&(userGlobalState.value.userId!==undefined)&&(userGlobalState.value.userId!==null) ){router.push({path:'/tai-khoan/'})};
    };
    const buttonf7e3cef73fc44e9054110eee9face29daf299738click = async function(signupGraphUser){
        toast?.show({props:{title:'Thông báo',body:'Tạo tài khoản thành công',variant:'success'}});user.value.email=signupGraphUser.email;user.value.password=register.value?.password;await loginGraphUser();  await new Promise((re)=>{
                    setTimeout(()=>{re(true);},30);
                });;userGlobalState.value.isshowloginform=false;register.value.email='';;register.value.fullName='';
    };
    const buttoneb9cb39ef223cbac75d9ba3f5be0232228e41a23click = async function(){
        let stepBreak=0;;if( (temploginemail.value==='' || user.value.password==='')&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'Thông báo',body:'Email hoặc mật khẩu không đúng',variant:'danger'}})};if( temploginemail.value==='' || user.value.password==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){user.value.email=toLower(temploginemail.value)};if( (!stepBreak||stepBreak===4) ){await loginGraphUser1()};if( (!stepBreak||stepBreak===5) ){temploginemail.value=''};if( (!stepBreak||stepBreak===6) ){user.value.email=''};if( (!stepBreak||stepBreak===7) ){};
    };
    const button0cb4ecc38eaf3ae66737a71527971767d1acaaf4click = async function(){
        let stepBreak=0;;if( (emailUserFind.value==='' || phoneUserFind.value==='')&&(!stepBreak||stepBreak===1) ){toast?.show({props:{title:'',body:'Vui lòng nhập đủ Email và Số điện thoại đã đăng ký',variant:'warning'}})};if( emailUserFind.value==='' || phoneUserFind.value==='' ){
 stepBreak=999};if( (!stepBreak||stepBreak===3) ){await loadDatauserByEmail()};if( (!stepBreak||stepBreak===4) ){  await new Promise((re)=>{
                    setTimeout(()=>{re(true);},10);
                });};if( ((emailUserFind.value!=='')&&(phoneUserFind.value!=='')&&(userByEmail.value._id===undefined))&&(!stepBreak||stepBreak===5) ){toast?.show({props:{title:'',body:'Không tìm thấy tài khoản nào theo thông tin của bạn',variant:'danger'}})};if( ((emailUserFind.value!=='')&&(phoneUserFind.value!=='')&&(userByEmail.value._id!==undefined))&&(!stepBreak||stepBreak===6) ){toast?.show({props:{title:'',body:'Mời nhập mật khẩu mới',variant:'success'}})};
    };
    const buttonbaf7c940c9e3dafdab3249fee4b977f3800d9d5cclick = async function(capNhatQuenMatKhauUser){
        toast?.show({props:{title:'',body:'Đã cập nhật mật khẩu mới',variant:'success'}});user.value.email=userByEmail.value?.email;user.value.password=renewpass.value;await loginGraphUser2();userGlobalState.value.isshowloginform=false;
    };
    const div0c74aab2e86d93d27ef40f346154d7205b1399baclick = async function(listProductSearch,index){
        keyword.value='';showcanvassearchmobile.value=false;await loadDatasearchProductBrands();await loadDatalistProductSearch();
    };
    const column0c74aab2e86d93d27ef40f346154d7205b1399baclick = async function(searchProductBrands,index){
        keyword.value='';showcanvassearchmobile.value=false;await loadDatasearchProductBrands();await loadDatalistProductSearch();
    };
    const button9afa3282c5191ed44c8f9f275515eaa6bfcf054eclick = async function(){
        userLogoutAction.value.email=userGetMe.value?.email;await logoutGraphUserlogoutaction1();userGlobalState.value.userId='';userGlobalState.value.showlogoutmodal=false;reload();
    };
    const body48790a25a133bd5d540017fe10dc5506213c74b3created = async function(){
        await loadDatauserGetMe();userGlobalState.value.userId=userGetMe.value?._id;
    };
    





        //4 setup
        
        emailUserFind.value='YourEmail@gmail.com'
        

        let userGetMe= ref({});
let groupProductLv1s= ref([]);
let groupProductLv2s= ref([]);
let groupProductLv3s= ref([]);
let listProductSearchs= ref([]);
let searchProductBrandss= ref([]);
let webContentsVeHasakis= ref([]);
let websiteConfig= ref({});
let listHomeMenus= ref([]);
let groupProductForMobileMenus= ref([]);
let userByEmail= ref({});

        const slug=route.params['default'];

let variableuserGetMe={};

const resultuserGetMe = await useAsyncQuery(gql` query {me
     {
                _id email fullName 
        }
     }
`,variableuserGetMe);
if(resultuserGetMe.data.value&&resultuserGetMe.data.value?.me){
userGetMe.value = {...resultuserGetMe.data.value?.me}}

let variablegroupProductLv1s={sort :{orderNumber:1}, query :{groupProduct:{eq:null},status:{eq:true},}};

const resultgroupProductLv1s = await useAsyncQuery(gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv1s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug groupProduct highlight status thumbnail orderNumber icon secondName
        }

}`,variablegroupProductLv1s);
if(resultgroupProductLv1s.data.value&&resultgroupProductLv1s.data.value?.groupProductLv1s){
groupProductLv1s.value = resultgroupProductLv1s.data.value?.groupProductLv1s}

let variablegroupProductLv2s={sort :{orderNumber:1}, query :{status:{eq:true},}};

const resultgroupProductLv2s = await useAsyncQuery(gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv2s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                name slug _id highlight status groupProduct thumbnail secondName
        }

}`,variablegroupProductLv2s);
if(resultgroupProductLv2s.data.value&&resultgroupProductLv2s.data.value?.groupProductLv2s){
groupProductLv2s.value = resultgroupProductLv2s.data.value?.groupProductLv2s}

let variablegroupProductLv3s={sort :{orderNumber:1}, query :{status:{eq:true},}};

const resultgroupProductLv3s = await useAsyncQuery(gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductLv3s:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug status groupProduct thumbnail secondName
        }

}`,variablegroupProductLv3s);
if(resultgroupProductLv3s.data.value&&resultgroupProductLv3s.data.value?.groupProductLv3s){
groupProductLv3s.value = resultgroupProductLv3s.data.value?.groupProductLv3s}
if(keyword.value!='' || keyword.value!==''){

let variablelistProductSearchs={limit: 5, query :{status:{eq:true},}};
if( keyword.value!='' || keyword.value!==''){if( keyword.value===null || keyword.value==='' || keyword.value===undefined){variablelistProductSearchs.query.name = {eq:"ádasdasdasdasd"}
                              
}}if( keyword.value!='' || keyword.value!==''){
                                    if(keyword.value&&keyword.value!==''){
                                    variablelistProductSearchs.query.searchText = {search: keyword.value  } 
                                    
                                    }
                              
}
const resultlistProductSearchs = await useAsyncQuery(gql` query    
 products($query: productFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listProductSearchs:  products(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug image price 
        }

}`,variablelistProductSearchs);
if(resultlistProductSearchs.data.value&&resultlistProductSearchs.data.value?.listProductSearchs){
listProductSearchs.value = resultlistProductSearchs.data.value?.listProductSearchs}
}
let variablesearchProductBrandss={query :{status:{eq:true},}};

                                    if(keyword.value&&keyword.value!==''){
                                    variablesearchProductBrandss.query.name = {regex: keyword.value  } 
                                    
                                    }
                              
if( keyword.value===undefined || keyword.value==='' || keyword.value===null){variablesearchProductBrandss.query.name = {eq:"kjhkjhkjhkdjhfkdhg"}
                              
}
const resultsearchProductBrandss = await useAsyncQuery(gql` query    
 productBrandss($query: productBrandsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     searchProductBrandss:  productBrandss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug banner status 
        }

}`,variablesearchProductBrandss);
if(resultsearchProductBrandss.data.value&&resultsearchProductBrandss.data.value?.searchProductBrandss){
searchProductBrandss.value = resultsearchProductBrandss.data.value?.searchProductBrandss}

let variablewebContentsVeHasakis={sort :{orderNumber:1}};

const resultwebContentsVeHasakis = await useAsyncQuery(gql` query    
 webContentss($query: webContentsFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     webContentsVeHasakis:  webContentss(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id title slug groupWebContent thumbnail 
        }

}`,variablewebContentsVeHasakis);
if(resultwebContentsVeHasakis.data.value&&resultwebContentsVeHasakis.data.value?.webContentsVeHasakis){
webContentsVeHasakis.value = resultwebContentsVeHasakis.data.value?.webContentsVeHasakis}

let variablewebsiteConfig={};

const resultwebsiteConfig = await useAsyncQuery(gql` query {  websiteConfig {
                _id logo fanpage tenMien thongTinSoHuu soDienThoai email iconicLogo tenThuongHieuSeo linkGoogleMaps mediaItemGoogleMaps{ itemImage  itemLink  label  description  } instagram tiktok 
        } }`,variablewebsiteConfig);
if(resultwebsiteConfig.data.value&&resultwebsiteConfig.data.value?.websiteConfig){
websiteConfig.value = {...resultwebsiteConfig.data.value?.websiteConfig}}

let variablelistHomeMenus={sort :{orderNumber:1}, query :{displayOn:{in:["header"]},}};

const resultlistHomeMenus = await useAsyncQuery(gql` query    
 homeMenus($query: homeMenuFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     listHomeMenus:  homeMenus(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id label link secondLabel
        }

}`,variablelistHomeMenus);
if(resultlistHomeMenus.data.value&&resultlistHomeMenus.data.value?.listHomeMenus){
listHomeMenus.value = resultlistHomeMenus.data.value?.listHomeMenus}

let variablegroupProductForMobileMenus={sort :{orderNumber:1}, query :{status:{eq:true},}};

                                    if(selectedgroupproductlv1.value&&selectedgroupproductlv1.value!==''){
                                    variablegroupProductForMobileMenus.query.groupProduct = {in: selectedgroupproductlv1.value  } 
                                    
                                    }
                              

const resultgroupProductForMobileMenus = await useAsyncQuery(gql` query    
 groupProducts($query: groupProductFindInput,$limit:Float,$skip:Float,$sort:JSONObject){
     groupProductForMobileMenus:  groupProducts(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id name slug thumbnail groupProduct status secondName
        }

}`,variablegroupProductForMobileMenus);
if(resultgroupProductForMobileMenus.data.value&&resultgroupProductForMobileMenus.data.value?.groupProductForMobileMenus){
groupProductForMobileMenus.value = resultgroupProductForMobileMenus.data.value?.groupProductForMobileMenus}
if(emailUserFind.value!==null){

let variableuserByEmail={query :{}};
if( emailUserFind.value!==null){
                                
                                    variableuserByEmail.query.email = {eq: toLower(emailUserFind.value,"")  } 
                                    
                                    
                              
}if( emailUserFind.value!==null){
                                
                                    variableuserByEmail.query.phoneNumber = {eq: phoneUserFind.value  } 
                                    
                                    
                              
}
const resultuserByEmail = await useAsyncQuery(gql` query    
 lay1DuLieuTheoEmailUser($query: userLay1dulieutheoemailInput,$limit:Float,$skip:Float,$sort:JSONObject){
     lay1DuLieuTheoEmail:  lay1DuLieuTheoEmailUser(query:$query,limit:$limit,skip:$skip,sort:$sort)
     {
                _id fullName email phoneNumber password 
        }

}`,variableuserByEmail);
if(resultuserByEmail.data.value&&resultuserByEmail.data.value?.lay1DuLieuTheoEmail){
userByEmail.value = {...resultuserByEmail.data.value?.lay1DuLieuTheoEmail}}
}







        //4 afterData
        
   if (groupProductLv1s.value.length > 0) {
    //Custom chỗ này để gán giá trị đầu tiên cho mảng
    selectedgroupproductlv1.value = groupProductLv1s.value[0]._id;
    console.log(selectedgroupproductlv1); 
} else {
    console.log('Mảng groupProductLv1 rỗng');
};loadDatagroupProductForMobileMenu()

//set locale vào biến
userGlobalState.value.language = locale.value;
console.log("userGlobalState.value.language: >>>>>>> ", userGlobalState.value.language);
    

// console.log("ahhhhhhhhhhhhhhhhhhhhhh 3tr", locale._value);
        


        



        
        const reload=()=>{
            setTimeout(()=>{
window.location.reload();
},1000
)

        }
        const methods=()=>{
            
        }

        
        body48790a25a133bd5d540017fe10dc5506213c74b3created()
          onMounted(()=>{
            if( checkshowlanguage.value===false ){showlanguage.value=true};if( checkshowlanguage.value===false ){checkshowlanguage.value=true}
        })





  // watch



    











    //meta
        

        const meta = [  {name: "theme-color" ,content:"#fff"}];
        let title = '';

    title =''


         title= websiteConfig?.value.seoTitle;
            meta.push({ name: 'title', content:websiteConfig?.value.seoTitle });
            
            meta.push({ name: 'og:image', content:websiteConfig?.value.logo });
            
            meta.push({ name: 'description', content:websiteConfig?.value.seoDescription });
            
            meta.push({ name: 'og:url', content:websiteConfig?.value.tenMien });
            
            meta.push({ name: 'keywords', content:websiteConfig?.value.seoKeywords });
            


   const bodyAttrs ={};
    const  htmlAttrs={};
    



        useHead({
            title: title,
            viewport: 'width=device-width, initial-scale=1, maximum-scale=1',
            charset: 'utf-8',
            meta: meta,
            htmlAttrs:htmlAttrs,
            bodyAttrs:bodyAttrs,
            script: [
                    
            ],
            
            link:[

                
                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css' }, 
                
                
                
                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css' }, 
               
                    
            ]
            
        })
    


</script>


<style  >
    @import "assets/css/apieu.css";
@import "assets/css/ca-nhan.css";
@import "assets/css/cart.css";
@import "assets/css/cosmetic-two.css";
@import "assets/css/dienmay.css";
@import "assets/css/end-june.css";
@import "assets/css/halt-june.css";
@import "assets/css/index.css";
@import "assets/css/layouts.css";
@import "assets/css/mau-u-dai.css";
@import "assets/css/Nav.css";
@import "assets/css/san-pham.css";
@import "assets/css/start-july.css";
@import "assets/css/tapchi.css";
@import "assets/css/version-2.css";

</style>

<!--true-->
<script>


    
    export default {
        name: "default",
 // Composition API
 // Dev Khải ghi đè

        data(){
            return {

                }
            },
                methods:{




    }

        }
</script>

//update in Mon Dec 02 2024 16:59:02 GMT+0000 (Coordinated Universal Time) by loidirieng
   